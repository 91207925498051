import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

      <div className="page_header">
         <h2 className="sub_title">20 X 20 / 下半場</h2>
         <h1 className="main_title">蔡淳任</h1>
      </div>


      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_5">
            <div className="context">
              <p>從歷年的文化統計來看，視覺跟表演藝術的參與率一直在往下掉。這邊的「參與」，以文化統計的定義，指的是以觀眾的身分參與視覺或表演藝術的活動。視覺藝術對參與可能比較沒感覺，但是對票券收入占營收一定比例的表演藝術來講，參與率往下掉很令人擔憂，因為這表示願意看表演的觀眾越來越少。</p>
              <p>為了搞清楚到底「為什麼」觀眾願意/不願意買票進劇場，表盟從今年中開始了一系列八場的論壇，到最近剛結束第一階段的意見收集。初步分析第一階段的意見彙整結果，學校或家庭的啟蒙是相當重要的「啟蒙」點。即使中間歷經各種參與的斷層，許多參與的朋友表示，學校或是家庭帶著看表演(甚至是上課看錄影)，都在他們的心中埋了一顆種子。</p>
              <p>在學校與家庭因素啟蒙之後，接下來參與的朋友多把因素歸到各種行銷、同儕、票價、交通、工作坊、導聆等，與相較於直接「進場」長短不一的因素。</p>
              <p>最核心的當然是對演出的內容「有沒有興趣」，有沒有相應的「理解」，或是更簡單的，「好不好看」。總歸一句話，就是節目觀賞的經驗好壞。</p>
              <p>當然還有很多環節待研究跟釐清。但是從前述的結果，我們或許可以節目的「內容」好壞為核心，歸納出一個吸引觀眾參與的迴圈假說，由小時候的觀賞經驗啟蒙，到長大之後一連串體驗回饋，決定一個觀眾願意花多少時間在「進場」這件事情上。</p>
              <p>那這個又跟社會住宅甚至公共藝術什麼關係呢?</p>
              <p>前述的觀眾經驗多聚焦在「家庭」跟「學校」，學校尤多。但是學校教育畢竟遠水救不了近火(或許專營親子節目的團隊比較有感吧)。再加上一線的教學壓力、城鄉的文化/學習資源差距等等因素，究竟求學階段的各種藝術教育課程或體驗要多到什麼程度，才可以促進這些小朋友長大後依然願意進場，那個發展路徑依舊是個未知數。</p>
              <p>但是「家庭」或「社區」，對我來說完全是個未知的藍海。一則很多學生要進場還得看自己的成績單、父母的錢包或臉色，二則在文化統計下各種藝術參與率不到50%的今天，沒有道理忽視這些可能的「潛在」觀眾。</p>
              <p>藝術參與這件事情，各領域的朋友都非常努力的在降低進場的「門檻」。以下純為個人意見：不管是各種工作坊、導聆、博物館的宣傳等族繁不及備載的手法，對許多人來說，進場都是一個需要「刻意」為之的事情，要考慮的事情非常多，特別是跟錢還有工作時間相撞的時候。當我們把廣告詞或文案打上「ＯＯ也看得懂」或是「ＸＸ很簡單」的時候，反過來可能就是在加深一種，ＯＯＸＸ很難懂的印象。也不是那些ＯＯＸＸ真的很難懂，而是觀眾會去衡量，我花這個錢到底懂不懂這些ＯＯＸＸ，或是就乾脆挑免費的看，反正看不懂可以閃人，荷包還沒有損失。在萬物飛漲薪水不漲的年代，要大家掏錢出來看表演看展覽或許就更難了。學生至少還有學校可以支援，家長或是大一點的族群在失去學校的資源之後，還願不願意進場也是未知數。</p>
              <p>透過各種觀眾培養計畫扭轉這種印象或加深理解大家都在做。但是社會住宅＋公共藝術，或許是另外一種可能的觀眾參與模式。</p>
              <p>甚至，是「社會住宅」加上「公共藝術」，才讓進一步去接觸這些潛在觀眾變成可能。</p>
              <p>首先，社會住宅瞄準的族群是剛出社會，或是收入相對不高的族群。而把公共藝術透過１％的機制綁在這個居住空間裡面，等於是讓過去散落在各種藝文資源匱乏社區的族群，得以透過公共藝術或社會住宅的回饋計畫或機制，得到如前述來自家庭或學校的「啟蒙」機會。</p>
              <p>其次，當前的「公共藝術」，在公共藝術設置辦法鬆綁之後，也已經不限實體的「作品」，而是開放各種各類型的計畫，均可以使用這１％的經費。</p>
              <p>而社會住宅的「公有」性，或許也能讓各種藝術推廣計畫擺脫一點資源匱乏的窘境。做社區的藝文團體不算少，但是多半需要靠著文化局少少的社區營造補助過活。但是社會住宅不是，他是龐大營造計畫的「１％」。當然不能老是靠這１％經費，既然社會住宅的公共藝術擺脫半永久的物件採購，那當然得思考如何讓這種軟性的藝術計畫如何能持續下去。</p>
              <p>但無論如何，社會住宅的公共藝術計畫，都是一個相較於場館或學校，能更讓藝術直接能夠直面公眾的狀況（當然面對的公眾群體多樣性可能更複雜）。或許，也是一個各類型藝術可以讓自己的同溫層推廣更加延展的機會。回到最前頭的參與率，如果那個文化或美學同溫層能擴展，讓「刻意」成為「自然」，或許大家的機會也會比較多一點。</p>
            </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_1_10" className="next_page">
            <div className="next_title">方彥翔</div>
            <div className="next_owner">20 X 20 / 下半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
